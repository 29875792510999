import React from 'react';
import { Link } from 'react-router-dom';
import store from "../../utils/store";
import { connect } from "react-redux";
import UIkit from "uikit";

class Articles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            seasons: [],
            current: {
                seasonId: props.articles.current.seasonId
            },
            news: [],
            newsDetail: {
                comments: []
            }
        };

        this.closeNewsDetail = this.closeNewsDetail.bind(this);
    }

    componentDidMount() {
        const accessToken = localStorage.getItem("accessToken");

        let modalTrainingDone = document.getElementById('tm-modal-workouts-training-done');
        let modalTrainingCircle = document.getElementById('tm-modal-workouts-training-circle');

        if (modalTrainingDone) {
            modalTrainingDone.parentNode.removeChild(modalTrainingDone);
        }

        if (modalTrainingCircle) {
            modalTrainingCircle.parentNode.removeChild(modalTrainingCircle);
        }

        store.dispatch({
            type: "CHANGE_PAGE",
            payload: {
                body: {
                    class: "tm-page-articles"
                },
                header: {
                    navigation: true,
                    title: "Материалы"
                }
            }
        });

        UIkit.offcanvas("#offcanvas-usage").hide();

        store.dispatch({
            type: "TOGGLE_CHAT",
            payload: {
                isOpen: false
            }
        });

        fetch( `https://api-slender.zubareva.online/api/profile/seasons`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : accessToken
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    seasons: result.list
                })
            },(error) => {
                console.log(error)
            }
        );
    }

    componentWillMount() {
        const accessToken = localStorage.getItem("accessToken");

        this.actionGetArticles();

        if (!accessToken) {
            this.props.history.push('/signin');
        }
    }

    actionGetArticles = () => {
        const accessToken = localStorage.getItem("accessToken");

        fetch( `https://api-slender.zubareva.online/api/learn/list?&page=1&season_id=${this.state.current.seasonId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : accessToken
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result)
                this.setState({
                    isLoaded: true,
                    news: result.list
                })
            },(error) => {
                console.log(error)
            }
        );
    };

    closeNewsDetail = () => {
        this.setState({
            newsDetail: {}
        })
    };

    async changeSeason(e) {
        if (e.target.value > 0) {
            await this.setState({
                current: {
                    ...this.state.current,
                    seasonId: parseInt(e.target.value)
                }
            });

            store.dispatch({
                type: "ARTICLES_CHANGE_SEASON",
                payload: parseInt(e.target.value)
            });

            this.actionGetArticles();
        }
        else{
            await this.setState({
                news: [],
                current: {
                    ...this.state.current,
                    seasonId: null
                }
            });

            store.dispatch({
                type: "ARTICLES_CHANGE_SEASON",
                payload: null
            });
        }
    };

    render() {
        const { isLoaded, news, newsDetail, seasons, season } = this.state;

        return (
            <div className="uk-container uk-container-large uk-section">
                <div className="uk-hidden@m uk-flex uk-flex-middle">
                    <h2 className="uk-h2 uk-margin-remove" style={{fontWeight: 700}}>Материалы</h2>
                    <div className="uk-margin-left">
                        <div className="tm-button-change-course" data-uk-toggle="target: #tm-modal-change-course">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.8811 12.6316H10.1029C9.82168 11.7379 8.98418 11.0879 7.9998 11.0879C7.01543 11.0879 6.17793 11.7379 5.89668 12.6316H1.11855C0.75293 12.6316 0.456055 12.9285 0.456055 13.2941C0.456055 13.6598 0.75293 13.9566 1.11855 13.9566H5.89668C6.17793 14.8504 7.01543 15.5004 7.9998 15.5004C8.98418 15.5004 9.82168 14.8504 10.1029 13.9566H14.8811C15.2467 13.9566 15.5436 13.6598 15.5436 13.2941C15.5436 12.9285 15.2467 12.6316 14.8811 12.6316ZM7.9998 14.1754C7.5123 14.1754 7.11855 13.7785 7.11855 13.2941C7.11855 12.8066 7.51543 12.4129 7.9998 12.4129C8.48418 12.4129 8.88105 12.8098 8.88105 13.2941C8.88105 13.7816 8.4873 14.1754 7.9998 14.1754Z" fill="#DD9D9D"/>
                                <path d="M14.8811 2.04375H13.6311C13.3498 1.15 12.5123 0.5 11.5279 0.5C10.5436 0.5 9.70605 1.15 9.4248 2.04375H1.11855C0.75293 2.04375 0.456055 2.34062 0.456055 2.70625C0.456055 3.07187 0.75293 3.36875 1.11855 3.36875H9.4248C9.70605 4.2625 10.5436 4.9125 11.5279 4.9125C12.5123 4.9125 13.3498 4.2625 13.6311 3.36875H14.8811C15.2467 3.36875 15.5436 3.07187 15.5436 2.70625C15.5436 2.34062 15.2467 2.04375 14.8811 2.04375ZM11.5279 3.5875C11.0404 3.5875 10.6467 3.19062 10.6467 2.70625C10.6467 2.22187 11.0436 1.825 11.5279 1.825C12.0154 1.825 12.4092 2.22187 12.4092 2.70625C12.4092 3.19062 12.0154 3.5875 11.5279 3.5875Z" fill="#DD9D9D"/>
                                <path d="M14.8811 7.3377H4.76543C4.48418 6.44395 3.64668 5.79395 2.6623 5.79395C1.44668 5.79395 0.456055 6.78457 0.456055 8.0002C0.456055 9.21582 1.44668 10.2064 2.6623 10.2064C3.64668 10.2064 4.48418 9.55645 4.76543 8.6627H14.8811C15.2467 8.6627 15.5436 8.36582 15.5436 8.0002C15.5436 7.63457 15.2467 7.3377 14.8811 7.3377ZM2.6623 8.88145C2.1748 8.88145 1.78105 8.48457 1.78105 8.0002C1.78105 7.51582 2.17793 7.11895 2.6623 7.11895C3.14668 7.11895 3.54355 7.51582 3.54355 8.0002C3.54355 8.4877 3.14668 8.88145 2.6623 8.88145Z" fill="#DD9D9D"/>
                            </svg>
                            <span>Выбрать курс</span>
                        </div>
                    </div>
                </div>
                {
                    (this.props.seasonState.id === 0 && this.props.seasonState.has === 1 && news.length === 0) && (
                        <div className="tm-wrapper-plug">
                            <h3 className="uk-3">Ожидайте начала сезона</h3>
                            <div>{this.props.seasonState.date}</div>
                        </div>
                    )
                }
                {
                    (this.props.seasonState.id === 0 && this.props.seasonState.has === 0 && news.length === 0) && (
                        <div className="tm-wrapper-plug">
                            <h3 className="uk-3">Мы не обнаружили у вас сезона</h3>
                            <a href="https://off-slender.zubareva.online" target="_blank" className="uk-button uk-button-primary">Купить</a>
                            <a href="https://wa.me/79182611437?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D1%83%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%20%D0%BF%D0%BE%20%D0%BC%D0%B0%D1%80%D0%B0%D1%84%D0%BE%D0%BD%D1%83%2C%20" target="_blank" className="uk-button uk-button-default uk-margin-left">Техническая поддержка</a>
                        </div>
                    )
                }
                {
                    seasons.length > 0 && (
                        <>
                            <div className="uk-visible@m tm-articles-header-buttons">
                                <div className="tm-button-change-course" data-uk-toggle="target: #tm-modal-change-course">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.8811 12.6316H10.1029C9.82168 11.7379 8.98418 11.0879 7.9998 11.0879C7.01543 11.0879 6.17793 11.7379 5.89668 12.6316H1.11855C0.75293 12.6316 0.456055 12.9285 0.456055 13.2941C0.456055 13.6598 0.75293 13.9566 1.11855 13.9566H5.89668C6.17793 14.8504 7.01543 15.5004 7.9998 15.5004C8.98418 15.5004 9.82168 14.8504 10.1029 13.9566H14.8811C15.2467 13.9566 15.5436 13.6598 15.5436 13.2941C15.5436 12.9285 15.2467 12.6316 14.8811 12.6316ZM7.9998 14.1754C7.5123 14.1754 7.11855 13.7785 7.11855 13.2941C7.11855 12.8066 7.51543 12.4129 7.9998 12.4129C8.48418 12.4129 8.88105 12.8098 8.88105 13.2941C8.88105 13.7816 8.4873 14.1754 7.9998 14.1754Z" fill="#DD9D9D"/>
                                        <path d="M14.8811 2.04375H13.6311C13.3498 1.15 12.5123 0.5 11.5279 0.5C10.5436 0.5 9.70605 1.15 9.4248 2.04375H1.11855C0.75293 2.04375 0.456055 2.34062 0.456055 2.70625C0.456055 3.07187 0.75293 3.36875 1.11855 3.36875H9.4248C9.70605 4.2625 10.5436 4.9125 11.5279 4.9125C12.5123 4.9125 13.3498 4.2625 13.6311 3.36875H14.8811C15.2467 3.36875 15.5436 3.07187 15.5436 2.70625C15.5436 2.34062 15.2467 2.04375 14.8811 2.04375ZM11.5279 3.5875C11.0404 3.5875 10.6467 3.19062 10.6467 2.70625C10.6467 2.22187 11.0436 1.825 11.5279 1.825C12.0154 1.825 12.4092 2.22187 12.4092 2.70625C12.4092 3.19062 12.0154 3.5875 11.5279 3.5875Z" fill="#DD9D9D"/>
                                        <path d="M14.8811 7.3377H4.76543C4.48418 6.44395 3.64668 5.79395 2.6623 5.79395C1.44668 5.79395 0.456055 6.78457 0.456055 8.0002C0.456055 9.21582 1.44668 10.2064 2.6623 10.2064C3.64668 10.2064 4.48418 9.55645 4.76543 8.6627H14.8811C15.2467 8.6627 15.5436 8.36582 15.5436 8.0002C15.5436 7.63457 15.2467 7.3377 14.8811 7.3377ZM2.6623 8.88145C2.1748 8.88145 1.78105 8.48457 1.78105 8.0002C1.78105 7.51582 2.17793 7.11895 2.6623 7.11895C3.14668 7.11895 3.54355 7.51582 3.54355 8.0002C3.54355 8.4877 3.14668 8.88145 2.6623 8.88145Z" fill="#DD9D9D"/>
                                    </svg>
                                    <span>Выбрать курс</span>
                                </div>
                            </div>
                            <div className="uk-hidden@m uk-clearfix">
                                <div className="uk-align-right uk-margin-remove">

                                </div>
                            </div>
                        {
                        isLoaded ? (
                            <div className="tm-articles-list">
                                <div className="uk-grid-match uk-child-width-1-3@s" data-uk-grid>
                                    {
                                        news.map((articleItem, key) => (
                                            <div key={key}>
                                                <div className="tm-articles-list-item">
                                                    <div className="tm-picture">
                                                        <img src={articleItem.picture} alt="" />
                                                    </div>
                                                    <div className="tm-author">
                                                        <span>{articleItem.user.name} {articleItem.user.last_name}</span>
                                                    </div>
                                                    <h5 className="uk-h5" style={{fontWeight: 700}}>{articleItem.name}</h5>
                                                    <span className="tm-date">{articleItem.date}</span>
                                                    <Link to={`/articles/${articleItem.id}`} className="uk-button uk-button-primary">
                                                        {
                                                            articleItem.read === 0 && ("Читать")
                                                        }
                                                        {
                                                            articleItem.read === 1 && ("Выполнить")
                                                        }
                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ) : ''
                        }
                        </>
                    )
                }
                <div id="tm-modal-change-course" className="uk-flex-top" data-uk-modal="stack: true;">
                    <div className="uk-modal-dialog uk-margin-auto-vertical uk-width-1-3@s">
                        <button className="uk-modal-close-full uk-close-large" type="button" data-uk-close />
                        <div className="uk-padding-large uk-text-center">
                            <h3 className="uk-h3 uk-text-bolder">Выбрать курс</h3>
                            <div data-uk-grid>
                                <div className="uk-width-1-2">
                                    <select className="uk-select" onChange={(e) => this.changeSeason(e)}>
                                        <option value="">Выберите курс</option>
                                        {
                                            seasons.map((seasonItem, key) => (
                                                <option key={key} value={seasonItem.id} selected={seasonItem.id === this.props.articles.current.seasonId ? true : false}>
                                                    {seasonItem.season_date}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="uk-margin-medium-top">
                                <div className="uk-button uk-button-primary uk-modal-close">Ок</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;
export default connect(mapStateToProps)(Articles);